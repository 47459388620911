import HomeRoutes from "./modules/home";
import MealsRoutes from "./modules/meals";
import ProfileRoutes from "./modules/profile";
import HelpRoutes from "./modules/help";
import SurveysRoutes from "./modules/surveys";
import EventsRoutes from "./modules/events";
import BenefitsRoutes from "./modules/benefits";
import CommunicationRoutes from "./modules/communication";
import CalendarRoutes from "./modules/calendar";
import TeamBuildingRoutes from "./modules/teambuilding";
import BookingsRoutes from "./modules/bookings";

export default [
  {
    path: "/employee",
    meta: {
      middleware: "auth",
    },
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    children: [
      ...HomeRoutes,
      ...MealsRoutes,
      ...ProfileRoutes,
      ...HelpRoutes,
      ...SurveysRoutes,
      ...EventsRoutes,
      ...BenefitsRoutes,
      ...CommunicationRoutes,
      ...CalendarRoutes,
      ...TeamBuildingRoutes,
      ...BookingsRoutes,
    ],
  },
];
