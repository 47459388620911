export default [
  {
    path: "/superadmin/employees",
    children: [
      {
        path: "/superadmin/employees/",
        name: "employees-index",
        component: () =>
          import("@/views/roles/super-admin/employees/employees/Index.vue"),
        meta: {
          pageTitle: "Empleados",
          breadcrumbs: ["Admin", "Empleados"],
        },
      },
      {
        path: "/superadmin/employees/create",
        name: "employees-create",
        component: () =>
          import("@/views/roles/super-admin/employees/employees/Create.vue"),
        meta: {
          pageTitle: "Empleados",
          breadcrumbs: ["Admin", "Empleados", "Create"],
        },
      },
      {
        path: "/superadmin/employees/edit/:id?",
        name: "employees-edit",
        component: () =>
          import("@/views/roles/super-admin/employees/employees/Edit.vue"),
        meta: {
          pageTitle: "Empleados",
          breadcrumbs: ["Admin", "Empleados", "Edit"],
        },
      },
      {
        path: "/superadmin/employees/details/:id?",
        name: "employees-details",
        component: () =>
          import("@/views/roles/super-admin/employees/employees/Details.vue"),
        meta: {
          pageTitle: "Empleados",
          breadcrumbs: ["Admin", "Empleados", "Details"],
        },
      },
    ],
  },
];
